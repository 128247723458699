export const TeamOneItemsArray = [
  {
    src: "/img/team/team_img01.jpg",
    src2: "/img/team/h3_team_img01.png",
    title: "Serhii Ivanenko",
    designation: "Office Cleaner",
    url: "/team-details",
  },
  {
    src: "/img/team/team_img02.jpg",
    src2: "/img/team/h3_team_img02.png",
    title: "William Adams",
    designation: "Window Cleaner",
    url: "/team-details",
  },
  {
    src: "/img/team/team_img03.jpg",
    src2: "/img/team/h3_team_img03.png",
    title: "Dianne Russel",
    designation: "House Cleaner",
    url: "/team-details",
  },
  {
    src: "/img/team/team_img04.jpg",
    src2: "/img/team/h3_team_img04.png",
    title: "David Martin",
    designation: "Office Cleaner",
    url: "/team-details",
  },
  {
    src: "/img/team/team_img05.jpg",
    src2: "/img/team/h3_team_img05.png",
    title: "Maria David",
    designation: "Window Cleaner",
    url: "/team-details",
  },
  {
    src: "/img/team/team_img06.jpg",
    src2: "/img/team/h3_team_img06.png",
    title: "Martin David",
    designation: "House Cleaner",
    url: "/team-details",
  },
  {
    src: "/img/team/team_img07.jpg",
    src2: "/img/team/h3_team_img07.png",
    title: "Robert Kolee",
    designation: "Office Cleaner",
    url: "/team-details",
  },
  {
    src: "/img/team/team_img08.jpg",
    src2: "/img/team/h3_team_img08.png",
    title: "Johan Molle",
    designation: "House Cleaner",
    url: "/team-details",
  },
  {
    src: "/img/team/team_img09.jpg",
    src2: "/img/team/h3_team_img09.png",
    title: "Maria Martin",
    designation: "House Cleaner",
    url: "/team-details",
  },
];
